import React from 'react'

type Props = {
  [key: string]: any
}

export default React.memo((props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133.3 38" aria-labelledby="Devcon Archive logo">
      <title>Devcon Archive logo</title>
      <g>
        <path
          fill="#2F354B"
          d="M22,19.4L11,0L0,19.4l11,7.1L22,19.4z M11.8,12.9V4.7l6.9,12.2L11.8,12.9z M19.4,19.1l-7.6,4.9v-9.3L19.4,19.1
			z M10.2,12.9l-6.9,4l6.9-12.2V12.9z M10.2,14.8v9.3l-7.6-4.9L10.2,14.8z"
        />
        <path
          fill="#2F354B"
          d="M11,28.8L0,21.7L11,38l11-16.3L11,28.8z M10.1,30.2L10.1,30.2l0.1,3.8l-4.5-6.6L10.1,30.2z M16.3,27.3
			l-4.5,6.6v-3.7l0.1,0L16.3,27.3z"
        />
      </g>
      <path
        fill="#30354B"
        d="M40.3,31.9h-5.9l-1,3.4h-4.6l6.6-17.9h4.1L46,35.2h-4.6L40.3,31.9z M35.4,28.5h3.9l-1.9-6.2L35.4,28.5z
		M53.8,28.9h-2.3v6.3h-4.3V17.3h7.1c2.1,0,3.8,0.5,5,1.4c1.2,0.9,1.8,2.3,1.8,4c0,1.2-0.3,2.3-0.8,3.1c-0.5,0.8-1.3,1.5-2.4,2
		l3.7,7.2v0.2h-4.6L53.8,28.9z M51.4,25.6h2.7c0.8,0,1.4-0.2,1.8-0.6c0.4-0.4,0.6-1,0.6-1.8c0-0.8-0.2-1.4-0.6-1.8
		c-0.4-0.4-1-0.7-1.8-0.7h-2.7V25.6z M77.8,29.2c-0.1,1.2-0.4,2.4-1,3.3c-0.6,1-1.5,1.7-2.6,2.2c-1.1,0.5-2.4,0.8-3.8,0.8
		c-2.4,0-4.2-0.8-5.6-2.3c-1.4-1.5-2-3.7-2-6.5v-0.9c0-1.8,0.3-3.3,0.9-4.6c0.6-1.3,1.5-2.3,2.7-3.1c1.2-0.7,2.5-1.1,4-1.1
		c2.2,0,3.9,0.6,5.3,1.7c1.3,1.1,2.1,2.7,2.3,4.7h-4.3c0-1.1-0.3-1.9-0.8-2.4c-0.5-0.5-1.3-0.7-2.4-0.7c-1.1,0-1.9,0.4-2.4,1.2
		c-0.5,0.8-0.8,2.1-0.8,3.9v1.3c0,1.9,0.2,3.3,0.7,4.1c0.5,0.8,1.3,1.2,2.5,1.2c1,0,1.8-0.2,2.3-0.7c0.5-0.5,0.8-1.2,0.8-2.2H77.8z
		M94.6,35.2h-4.3v-7.5H84v7.5h-4.3V17.3H84v7.1h6.4v-7.1h4.3V35.2z M101.7,35.2h-4.3V17.3h4.3V35.2z M111.5,30.3l3.6-13.1h4.8
		l-6,17.9h-4.7l-6-17.9h4.8L111.5,30.3z M132.1,27.6h-6.8v4.3h8v3.3h-12.4V17.3h12.4v3.3h-8.1v3.8h6.8V27.6z"
      />
      <g>
        <path
          fill="#2F354B"
          d="M32.8,2.9h4c3.1,0,5.2,1.9,5.2,4.8c0,2.8-2.1,4.8-5.2,4.8h-4V2.9z M36.8,11.3c2.4,0,3.9-1.4,3.9-3.6
			c0-2.1-1.5-3.6-3.9-3.6h-2.6v7.2L36.8,11.3L36.8,11.3z"
        />
        <path fill="#2F354B" d="M53.8,11.3v1.2h-7V2.9h6.8v1.2h-5.4v2.9H53v1.2h-4.8v3.1L53.8,11.3L53.8,11.3z" />
        <path fill="#2F354B" d="M67,2.9l-4.2,9.6h-1.4l-4.2-9.6h1.5l3.5,7.9l3.5-7.9H67z" />
        <path
          fill="#2F354B"
          d="M70.1,7.7c0-2.8,2.2-4.9,5.1-4.9c1.5,0,2.8,0.5,3.6,1.5l-0.9,0.9c-0.7-0.8-1.6-1.1-2.7-1.1
			c-2.2,0-3.8,1.6-3.8,3.7c0,2.1,1.6,3.7,3.8,3.7c1.1,0,2-0.4,2.7-1.1l0.9,0.9c-0.9,1-2.2,1.5-3.7,1.5C72.2,12.6,70.1,10.6,70.1,7.7z
			"
        />
        <path
          fill="#2F354B"
          d="M82.5,7.7c0-2.8,2.2-4.9,5.1-4.9c2.9,0,5.1,2.1,5.1,4.9c0,2.8-2.2,4.9-5.1,4.9C84.7,12.6,82.5,10.5,82.5,7.7z
			M91.3,7.7c0-2.1-1.6-3.7-3.7-3.7c-2.1,0-3.7,1.6-3.7,3.7c0,2.1,1.6,3.7,3.7,3.7C89.7,11.4,91.3,9.8,91.3,7.7z"
        />
        <path fill="#2F354B" d="M105.8,2.9v9.6h-1.1l-5.8-7.1v7.1h-1.4V2.9h1.1l5.8,7.1V2.9H105.8z" />
      </g>
    </svg>
  )
})
