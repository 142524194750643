import React from 'react'

type Props = {
  [key: string]: any
}

export default React.memo((props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 162 38">
      <title>Devcon logo</title>
      <path d="M42.5352 12.037H49.1515C54.1978 12.037 57.6297 15.2221 57.6297 19.8872C57.6297 24.5524 54.1984 27.7374 49.1515 27.7374H42.5352V12.037ZM49.0167 25.7857C52.8969 25.7857 55.3867 23.4085 55.3867 19.8867C55.3867 16.3655 52.8969 13.9877 49.0167 13.9877H44.7776V25.7852H49.0167V25.7857Z" />
      <path d="M76.9424 25.7857V27.7369H65.5488V12.0365H76.6284V13.9877H67.7918V18.8099H75.6642V20.7161H67.7918V25.7846H76.9424V25.7857Z" />
      <path d="M98.4753 12.037L91.5899 27.7374H89.3691L82.4609 12.037H84.8831L90.5353 24.9336L96.2318 12.037H98.4753Z" />
      <path d="M103.523 19.8867C103.523 15.2443 107.067 11.8573 111.845 11.8573C114.267 11.8573 116.375 12.6873 117.811 14.3017L116.353 15.7147C115.142 14.4365 113.662 13.8534 111.935 13.8534C108.391 13.8534 105.766 16.4104 105.766 19.8867C105.766 23.3635 108.391 25.9205 111.935 25.9205C113.662 25.9205 115.142 25.3147 116.353 24.0365L117.811 25.4495C116.376 27.0639 114.267 27.9166 111.822 27.9166C107.067 27.9161 103.523 24.5302 103.523 19.8867Z" />
      <path d="M123.891 19.8867C123.891 15.2892 127.435 11.8573 132.257 11.8573C137.034 11.8573 140.578 15.2659 140.578 19.8867C140.578 24.5075 137.035 27.9161 132.257 27.9161C127.435 27.9161 123.891 24.4853 123.891 19.8867ZM138.335 19.8867C138.335 16.4104 135.733 13.8534 132.257 13.8534C128.736 13.8534 126.134 16.4104 126.134 19.8867C126.134 23.3635 128.736 25.9205 132.257 25.9205C135.733 25.9205 138.335 23.3635 138.335 19.8867Z" />
      <path d="M161.998 12.037V27.7374H160.159L150.739 16.0293V27.7369H148.496V12.0365H150.335L159.755 23.7441V12.037H161.998Z" />
      <path d="M21.9999 19.0913L10.9971 0L0 19.0913L11.0027 26.0953L21.9999 19.0913ZM11.7962 12.6972V4.58854L18.7451 16.6456L11.7962 12.6972ZM19.396 18.8542L11.7962 23.6947V14.5361L19.396 18.8542ZM10.1976 12.6972L3.25602 16.6416L10.1976 4.59055V12.6972ZM10.1976 14.5361V23.6879L2.60143 18.8521L10.1976 14.5361Z" />
      <path d="M10.9965 28.3581L0.00585938 21.3541L10.9969 37.418L21.9997 21.3541L10.9965 28.3581ZM10.1378 29.7063L10.1974 29.7446V33.4189L5.74012 26.904L10.1378 29.7063ZM16.2626 26.9012L11.796 33.4221V29.7442L11.8552 29.7063L16.2626 26.9012Z" />
    </svg>
  )
})
